body,
html {
    margin: 0;
    padding: 0;
    border: 0;
    font-family: "Roboto", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    width: 100%;
    max-width: 100%;
    height: 100%;
    position: relative;
}

*,
*:focus,
*:active,
*:hover,
*:visited,
*:focus-within {
    outline: 0;
    -webkit-outline: none;
    -webkit-appearance: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    /*position: relative;*/
}

div#root {
    width: 100%;
    max-width: 100%;
    min-height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

div#root.fullscreen {
    height: 100%;
}

/* vertical timeline fix */
.vertical-timeline-element-content .vertical-timeline-element-date,
.vertical-timeline-element-content p {
    font-size: inherit !important;
    font-weight: inherit !important;
}

.vertical-timeline-element-content p {
    margin: 0 !important;
    line-height: inherit !important;
}
